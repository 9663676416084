import React, { useEffect } from "react";
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from '@chakra-ui/pro-theme'
import { extendTheme, Box } from '@chakra-ui/react'
import { useStoryblok, StoryblokComponent } from "@storyblok/react";

const proTheme = extendTheme(theme)
const extendedConfig = {
  colors: { ...proTheme.colors, brand: proTheme.colors.blue },
}
const myTheme = extendTheme(extendedConfig, proTheme)

function App() {

  useEffect(() => {
    document.title = "The Long Journey";
  }, []);

  let slug =
    window.location.pathname === "/"
      ? "home"
      : window.location.pathname.replace("/", "");

  let version = process.env.REACT_APP_MODE === "production" ? "published" : "draft";

  const story = useStoryblok(slug, { version: version });
  if (!story || !story.content) {
    return <Box>Loading...</Box>;
  }

  return (
    <ChakraProvider theme={myTheme}>
      <StoryblokComponent blok={story.content} />
    </ChakraProvider>
  );
}

export default App;
