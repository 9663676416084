import { storyblokEditable } from "@storyblok/react";
import RenderChakraRichText from "./utilities/RenderChakraRichText";
import { Box } from '@chakra-ui/react';

const Content = ({ blok }) => {
    return (
        <Box {...storyblokEditable(blok)} my={4}>
            {RenderChakraRichText(blok.content)}
        </Box>
    );
};

export default Content;