import { Box, Link, Heading, Image, Text, VStack, useColorModeValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const PostCard = ({ post }) => {


    const [contentWidth, setContentWidth] = useState(0)

    useEffect(() => {
        const handleResize = () => {
            setContentWidth(window.innerWidth)
        }

        handleResize()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const transformedImageUrl = `${post.content.cover_image.filename}/m/${contentWidth * 2}x0/filters:format(webp)`

    const bgColor = useColorModeValue('rgba(255, 255, 255, 0.7)', 'rgba(23, 25, 35, 0.7)');
    const textColor = useColorModeValue('gray.800', 'white');

    const allText = content => content.reduce((text, item) => text + (item.content ? allText(item.content) : item.text || ''), '');


    const hasImage = Boolean(post.content.cover_image && post.content.cover_image.filename);
    return (

        <Link href={`/flights/${post.slug}`} _hover={{ textDecoration: "none" }}>
            <Box position="relative" borderRadius="lg" overflow="hidden" borderWidth={!hasImage ? '1px' : ''} mb={4} p={!hasImage ? '4' : '0'}>
                {hasImage && (
                    <Image fetchpriority="high" src={transformedImageUrl} alt={post.content.cover_image.alt} objectFit="cover" w="100%" h="auto" _hover={{ transform: 'scale(1.05)', overflow: 'hidden', transitionProperty: 'transform', transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', transitionDuration: '150ms' }} transition="transform 150ms cubic-bezier(0.4, 0, 0.2, 1)" />
                )}
                <Box position={hasImage ? "absolute" : "relative"} bottom="0" p="4" w="full" bg={hasImage ? bgColor : ''}>
                    <VStack align="start" spacing="2">
                        <Heading as="h2" fontWeight="bold" color={textColor} fontSize={["3xl", "4xl"]} lineHeight={1.1}>
                            {post.content.title}
                        </Heading>
                        <Text fontSize="md" color={textColor}>
                            {new Date(post.content.flight_date).toLocaleDateString('en-US', {
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                            })}
                        </Text>
                        <Text noOfLines={2} fontSize="sm" color={textColor}>
                            {allText(post.content.body.content)}
                        </Text>
                    </VStack>
                </Box>
            </Box>
        </Link>
    );
};

export default PostCard;
