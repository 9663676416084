import { Box } from '@chakra-ui/react'
import SidebarWidget from './static/FlightStats'

const Sidebar = () => {
  return (
    <Box>
      <SidebarWidget />
    </Box>
  )
}

export default Sidebar