import React, { useEffect, useState } from 'react';
import StoryblokClient from 'storyblok-js-client';
import { Box, Flex, Link } from '@chakra-ui/react';

let token = process.env.REACT_APP_MODE === "production" ? process.env.REACT_APP_STORYBLOK_PUBLIC_TOKEN : process.env.REACT_APP_STORYBLOK_PREVIEW_TOKEN;
let version = process.env.REACT_APP_MODE === "production" ? "published" : "draft";
const storyblokApi = new StoryblokClient({
    accessToken: token,
    region: 'us'
});

const StoryNavigation = ({ slug }) => {
    const [stories, setStories] = useState([]);
    const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
    const [story, setStory] = useState(null);

    useEffect(() => {
        const fetchStoryAndStories = async () => {
            const normalizedSlug = slug.replace(/^\//, '');
            try {
                const storyResponse = await storyblokApi.get(`cdn/stories/${normalizedSlug}`, {
                    version: version
                });
                setStory(storyResponse.data.story);

                const storiesResponse = await storyblokApi.get('cdn/stories', {
                    starts_with: normalizedSlug.split('/')[0],
                    version: version,
                    sort_by: 'first_published_at:asc',
                    is_startpage: 0
                });

                setStories(storiesResponse.data.stories);
                const currentIndex = storiesResponse.data.stories.findIndex((s) => s.full_slug === normalizedSlug);
                setCurrentStoryIndex(currentIndex);

            } catch (error) {
                console.error("Failed to fetch story or stories", error);
            }
        };

        fetchStoryAndStories();
    }, [slug]);

    const prevStory = currentStoryIndex > 0 ? stories[currentStoryIndex - 1] : null;
    const nextStory = currentStoryIndex < stories.length - 1 ? stories[currentStoryIndex + 1] : null;

    return (
        <Box my={4} w="100%">
            <Flex minWidth='max-content' alignItems='center' justifyContent="space-between" width="100%" fontSize={["sm", "md"]}>
                {prevStory && (
                    <Link _hover={{}} href={`/${prevStory.full_slug}`} textAlign="center" bgColor="#F7D903" textDecoration="none" color="black" textTransform="uppercase" fontWeight={700} flex={1} borderWidth={2} borderRightWidth={[2, 1]} borderColor="black"><Box bgColor="#F7D903" p={[2, 4]} borderWidth={2}
                        borderColor="#F7D903"> &larr; Previous {prevStory.content.component === 'flight' ? 'Flight' : 'Story'}
                    </Box>
                    </Link>
                )}
                {/* Check if story is not null and then display its title */}
                {story ? <Box display={["none", "block"]} flex={4} textAlign="center" bgColor="black" color="#F7D903" fontWeight={700} borderWidth={2} borderColor="black" textTransform="uppercase"><Box borderWidth={2} borderColor="#F7D903" p={4}>{story.content.title}</Box></Box> : <Box flex={1} textAlign="center">Loading...</Box>}
                {nextStory && (
                    <Link _hover={{}} href={`/${nextStory.full_slug}`} textAlign="center" textDecoration="none" color="black" textTransform="uppercase" fontWeight={700} flex={1} borderWidth={2} borderColor="black"><Box bgColor="#F7D903" p={[2, 4]} borderWidth={2} borderLeftWidth={[2, 1]} borderColor="#F7D903">
                        Next {nextStory.content.component === 'flight' ? 'Flight' : 'Story'} &rarr;
                    </Box>
                    </Link>
                )}
            </Flex>
        </Box>
    );
};

export default StoryNavigation;
