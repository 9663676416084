import { Box, Image, Center } from '@chakra-ui/react'
import { storyblokEditable } from '@storyblok/react'
import { useEffect, useState } from 'react'

const FullWidthImage = ({ blok }) => {
    const [contentWidth, setContentWidth] = useState(0)

    useEffect(() => {
        const handleResize = () => {
            setContentWidth(window.innerWidth)
        }

        handleResize()

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const boxPadding = blok.caption ? 4 : 0
    const transformedImageUrl = `${blok.image.filename}/m/${contentWidth * 2}x0/filters:format(webp)`

    return (
        <Box {...storyblokEditable(blok)} my={4} p={boxPadding} bg="gray.50" borderBottomWidth={blok.caption ? 1 : 0}>
            <Image src={transformedImageUrl} alt={blok.image.alt} w="100%" borderRadius="lg" overflow="hidden" />
            {blok.caption ? <Center fontSize='sm' pt={4} fontStyle="italic" >{blok.caption}</Center> : null}
        </Box>
    );
};


export default FullWidthImage;