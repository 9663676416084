import { StoryblokComponent } from "@storyblok/react";
import {
    render, MARK_BOLD,
    MARK_ITALIC,
    MARK_UNDERLINE,
    MARK_CODE,
    MARK_LINK,
    NODE_HEADING,
    NODE_PARAGRAPH,
    NODE_QUOTE,
    NODE_IMAGE,
    NODE_LI,
    NODE_OL,
    NODE_UL,
    NODE_EMOJI
} from 'storyblok-rich-text-react-renderer';
import { Heading, Text, Image, Link } from '@chakra-ui/react';
const emoji = require('emoji-dictionary');


const RenderChakraRichText = (document) => {

    return (

        render(document, {
            markResolvers: {
                [MARK_BOLD]: (children) => <Text as="b">{children}</Text>,
                [MARK_ITALIC]: (children) => <Text as="i">{children}</Text>,
                [MARK_UNDERLINE]: (children) => <Text as="u">{children}</Text>,
                [MARK_CODE]: (children) => <Text as="code">{children}</Text>,
                [MARK_LINK]: (children, attrs) => <Link href={attrs.href}>{children}</Link>
            },
            nodeResolvers: {
                [NODE_HEADING]: (children) => <Heading>{children}</Heading>,
                [NODE_PARAGRAPH]: (children) => <Text mb={4}>{children}</Text>,
                [NODE_QUOTE]: (children) => <Text as="blockquote">{children}</Text>,
                [NODE_IMAGE]: (children, attrs) => <Image src={attrs.src} alt={attrs.alt} />,
                [NODE_LI]: (children) => <Text as="li">{children}</Text>,
                [NODE_OL]: (children) => <Text as="ol" marginLeft={4}>{children}</Text>,
                [NODE_UL]: (children) => <Text as="ul" marginLeft={4}>{children}</Text>,
                [NODE_EMOJI]: (children, attrs) => <Text as="span" role="img" aria-label="emoji">{emoji.getUnicode(attrs.name)}</Text>
            },
            defaultBlokResolver: (name, props) => {
                const blok = { ...props, component: name };
                return <StoryblokComponent blok={blok} key={props._uid} />;
            }

        })

    );
};

export default RenderChakraRichText;