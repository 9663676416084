import axios from 'axios';
import { useEffect, useState } from 'react';
import { Box, SimpleGrid, Image, Text, Link } from '@chakra-ui/react';

const STORYBLOK_API_URL = process.env.REACT_APP_STORYBLOK_API_URL;
let token = process.env.REACT_APP_MODE === "production" ? process.env.REACT_APP_STORYBLOK_PUBLIC_TOKEN : process.env.REACT_APP_STORYBLOK_PREVIEW_TOKEN;

// Determine the version based on the environment
let version = process.env.REACT_APP_MODE === "production" ? "published" : "draft";

const fetchPosts = async (display_post_count) => {
    const [aircraft, flights] = await axios.all([


        axios.get(STORYBLOK_API_URL, {
            params: {
                token: token,
                version: version, // Add the version parameter
                starts_with: 'aircraft/',
                content_type: "aircraft",
                sort_by: '',

            },
        }),
        axios.get(STORYBLOK_API_URL, {
            params: {
                token: token,
                version: version, // Add the version parameter
                starts_with: 'flights/',
                content_type: "flights",
                sort_by: '',

            },
        }),

    ]);
    return [aircraft.data.stories, flights.data.stories];

    //    return response.data.stories.slice(0, display_post_count);
};


const AircraftIndex = ({ blok }) => {
    const postCount = blok.display_post_count ? blok.display_post_count : 100;
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        fetchPosts(postCount).then(([aircraft, flights]) => {
            setPosts(aircraft);
        });
    }, [postCount]);

    return (



        <SimpleGrid columns={{ "base": 1, "md": 3 }} spacing={5} py={4}>
            {posts.map(post => {
                // let manufacturer = JSON.parse(post.content.manufacturer)
                return (
                    <Box key={post.id} position="relative" _hover={{ textDecoration: "none", transform: 'scale(1.05)', overflow: 'hidden', transitionProperty: 'transform', transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)', transitionDuration: '150ms' }} transition="transform 150ms cubic-bezier(0.4, 0, 0.2, 1)" >
                        <Link href={`https://www.flightaware.com/resources/registration/${post.slug}`} _hover={{ textDecoration: "none" }}>
                            <Image
                                src={post.content.photo_gallery[0].filename}
                                alt={post.name}
                                w="100%"
                                h="100%"
                                objectFit="cover"

                                borderRadius="lg"
                            />
                            <Box
                                position="absolute"
                                bottom="10px"
                                left="10px"
                                color="white"
                                bg="rgba(0, 0, 0, 0.5)"
                                p="2px 14px 6px"
                                borderRadius="5px"

                            >
                                <Text fontStyle="italic"
                                    fontSize={{ base: "1.5rem", md: "2rem" }}
                                    fontWeight={900}
                                    letterSpacing="-5%">{post.name}</Text>
                                <Text whiteSpace="nowrap">{post.content.manufacturer} {post.content.model_name}</Text>
                            </Box>

                        </Link>
                    </Box>
                )
            })}
        </SimpleGrid>

    );
};

export default AircraftIndex;
