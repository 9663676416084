import axios from 'axios';
import { useEffect, useState } from 'react';
import { Box, Link } from '@chakra-ui/react';
import PostCard from './PostCard';

const STORYBLOK_API_URL = process.env.REACT_APP_STORYBLOK_API_URL;
let token = process.env.REACT_APP_MODE === "production" ? process.env.REACT_APP_STORYBLOK_PUBLIC_TOKEN : process.env.REACT_APP_STORYBLOK_PREVIEW_TOKEN;

// Determine the version based on the environment
let version = process.env.REACT_APP_MODE === "production" ? "published" : "draft";

const fetchPosts = async (display_post_count) => {
    const response = await axios.get(STORYBLOK_API_URL, {
        params: {
            token: token,
            version: version, // Add the version parameter
            starts_with: 'flights/',
            content_type: "flight",
            sort_by: '',

        },
    });

    return response.data.stories.slice(0, display_post_count);
};

const BlogPosts = ({ blok }) => {
    const postCount = blok.display_post_count ? blok.display_post_count : 3;
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        fetchPosts(postCount).then((posts) => {
            setPosts(posts);
        });
    }, [postCount]);

    return (
        <Box>
            {posts.map((post) => (
                <PostCard key={post.id} post={post} />
            ))}
            <Link float="right" href="/flights" _hover={{ textDecoration: "none" }}>More Flights &rarr;</Link>
        </Box>

    );
};

export default BlogPosts;
