import { storyblokEditable } from "@storyblok/react";
import { useEffect, useState } from "react";

import Navbar from "../navbar/Navbar";
import Footer from "../Footer";
import StoryNavigation from "./StoryNavigation";
// import { CountUp } from "countup.js";


import { Box, Container, Heading, Text, Image, VStack, Center, HStack, SimpleGrid, Modal, ModalOverlay, ModalContent, ModalBody, ModalHeader, ModalFooter, ModalCloseButton, useDisclosure } from '@chakra-ui/react';
import RenderChakraRichText from "../utilities/RenderChakraRichText";


const FlightStoryLayout = ({ blok }) => {
    let slug = window.location.pathname;
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedImage, setSelectedImage] = useState(null);

    // Calendar Variables
    let month = blok.flight_date && new Date(blok.flight_date).toLocaleDateString('en-US', { month: 'long' });
    let day = blok.flight_date && new Date(blok.flight_date).toLocaleDateString('en-US', { day: 'numeric' });
    // Hobbs Meter Variables
    let hourSplit = blok.flight_hours && blok.flight_hours.split('.')[0];
    let minuteSplit = blok.flight_hours && (blok.flight_hours.split('.')[1] || "0");


    useEffect(() => {
        document.title = blok.title + " | The Long Journey";
    }, [blok.title]);

    console.log(blok);
    let aicraftId = blok.aircraft_flown.cached_url.split("/").pop().toUpperCase();

    return (

        <Box>
            <Box>
                <Navbar editable={false} />
            </Box>
            <Container>
                <Box {...storyblokEditable}>
                    <VStack align="start" spacing="4" my="8">
                        {/* Flight title and date */}


                        <StoryNavigation slug={slug} title={blok.title} />
                        <Heading as="h2" size="xl">{blok.title}</Heading>
                        {/* Cover image */}
                        {blok.cover_image.id && (
                            <Image
                                src={blok.cover_image.filename}
                                alt={blok.cover_image.alt || 'Flight cover image'}
                                borderRadius="md"
                            />
                        )}

                        {/* Story content */}
                        <Box my={4}>
                            {(blok.flight_date && blok.flight_hours) && (
                                <HStack align="center" w={["100%", "auto"]} p={6} shadow="md" background="blue.50">
                                    <Box shadow="md" width="72px" ratio={1}>
                                        <Box bg="red" px={2} py={1}>
                                            <Center color="white" fontWeight="bold" fontSize="14px">{month}</Center>
                                        </Box>
                                        <Box background="white">
                                            <Center fontSize="24px" fontWeight="bold">{day}</Center></Box>
                                    </Box>

                                    <Text fontSize="xl" fontWeight="bold">Flight Hours:</Text>
                                    <Box>
                                        <HStack spacing="0" fontFamily="Courier, serif" fontWeight="bold" fontSize="xl" color="white">
                                            <Box border="1px" borderColor="black" width="7" background="gray.700"><Center>0</Center></Box>
                                            <Box border="1px" borderColor="black" width="7" background="gray.700"><Center>0</Center></Box>
                                            <Box border="1px" borderColor="black" width="7" background="gray.700"><Center>0</Center></Box>
                                            <Box border="1px" borderColor="black" width="7" background="gray.700"><Center>{hourSplit}</Center></Box>
                                            <Box border="1px" borderColor="black" width="7" background="white"><Center fontSize="xl" color="black">{minuteSplit}</Center></Box>
                                        </HStack>
                                    </Box>
                                    <Text fontSize="xl" fontWeight="bold" pb={0}>Aircraft Flown:</Text>
                                    <Text fontSize="xl" mb={2}>{aicraftId}</Text>


                                </HStack>
                            )}
                            {RenderChakraRichText(blok.body)}
                        </Box>

                    </VStack>
                    <Box>
                        {blok.media_gallery && blok.media_gallery.length > 0 && (
                            <VStack align="start" spacing="4" my="8">
                                <SimpleGrid columns={3} spacing={4}>
                                    {blok.media_gallery.map((media, index) => (
                                        <Box key={index} shadow="md" borderRadius="md" overflow="hidden" borderWidth="1px" cursor="pointer" onClick={() => { setSelectedImage(media); onOpen(); }}>
                                            <Image
                                                src={media.filename}
                                                alt={media.alt || `Media ${index + 1}`}
                                                w="100%"
                                                h="auto"
                                            />
                                        </Box>
                                    ))}
                                </SimpleGrid>
                                <StoryNavigation slug={slug} my={8} />

                            </VStack>
                        )}
                    </Box>
                    <Modal size="6xl" isOpen={isOpen} onClose={onClose}>
                        <ModalOverlay bg='blackAlpha.300'
                            backdropFilter='blur(10px)' />
                        <ModalContent paddingBottom={selectedImage && selectedImage.title ? 0 : 8}>
                            <ModalHeader paddingBottom={8}>
                                <ModalCloseButton />
                            </ModalHeader>
                            <ModalBody px={10} paddingTop={2} paddingBottom={0}>
                                {selectedImage && <Image src={selectedImage.filename} alt={selectedImage.alt || "Selected media"} maxW="full" maxH="calc(60vh)" marginX="auto" />}
                            </ModalBody>
                            {selectedImage && selectedImage.title && (
                                <ModalFooter>
                                    <Text textAlign="center" w="full" fontSize="md">{selectedImage.title}</Text>
                                </ModalFooter>
                            )}
                        </ModalContent>
                    </Modal>
                </Box>
            </Container>
            <Footer />
        </Box>
    );
};

export default FlightStoryLayout;