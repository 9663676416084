import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { storyblokInit, apiPlugin } from "@storyblok/react";
import Teaser from './components/Teaser';
import Block from './components/Page';
import Navbar from './components/navbar/Navbar';
// import Posts from './components/posts/Posts';
import BlogPosts from './components/posts/BlogPosts';
import FullWidthImage from './components/post_blocks/FullWidthImage';
import Main from './components/Main';
import FlightStoryLayout from './components/posts/Flight';
import RichTextContent from './components/RichTextContent';
import FullIndex from './components/posts/FullIndex';
import Sidebar from './components/Sidebar';
import YoutubeEmbed from './components/post_blocks/YoutubeEmbed';
import AircraftIndex from './components/aircraft/AircraftIndex';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);

let token = process.env.REACT_APP_MODE === "production" ? process.env.REACT_APP_STORYBLOK_PUBLIC_TOKEN : process.env.REACT_APP_STORYBLOK_PREVIEW_TOKEN;


storyblokInit({
  accessToken: token,
  use: [apiPlugin],
  apiOptions: {
    "region": "us"
  },
  components: {
    'page': Block,
    'teaser': Teaser,
    'header': Navbar,
    'blog_posts': BlogPosts,
    'full_width_image': FullWidthImage,
    'home': Main,
    "flight": FlightStoryLayout,
    "rich_text": RichTextContent,
    "full_index": FullIndex,
    "sidebar": Sidebar,
    "youtube_embed": YoutubeEmbed,
    "aircraft_index": AircraftIndex,
  }
});


root.render(
  <StrictMode>
    <ColorModeScript />
    <App />
  </StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
