import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Image,
  HStack,
  Link,
  Skeleton,
  Stack,
  useBreakpointValue,
  useDisclosure,
  Drawer, DrawerBody, DrawerContent
} from '@chakra-ui/react'
import { ToggleButton } from './ToggleButton'
import { useStoryblok, storyblokEditable } from '@storyblok/react'
let version = process.env.REACT_APP_MODE

const Navbar = (props) => {
  const isDesktop = useBreakpointValue({
    base: false,
    lg: true,
  })
  const mobileNavbar = useDisclosure()

  const story = useStoryblok('site-structure/header');
  if (!story || !story.content) {
    return <Skeleton>Loading...</Skeleton>
  }

  return (

    <Box as="section" {...storyblokEditable(story.content)}>
      {version === 'draft' && (
        <Box bg="yellow.500" p="2" color="white" textAlign="center" fontWeight="bold">
          This is a development version of the site.
        </Box>
      )}
      <Box borderBottomWidth="1px" bg="bg.accent.default" position="relative" zIndex="tooltip">
        <Container py="4">
          <HStack justify="space-between">
            <Link href="/" _hover={{ textDecoration: 'none', transform: 'scale(1.05)' }}>
              <Image src={story.content.logo.filename + '/m/filters:format(webp)'} alt={story.content.logo.alt} w={[40, 60]} />
            </Link>
            {isDesktop ? (
              <HStack spacing="8">
                <ButtonGroup size="lg" variant="text" colorScheme="gray" spacing="8">
                  {story.content.navlinks
                    ? story.content.navlinks.map((blok) => (
                      <Button key={blok._uid}>
                        <Link href={`${window.location.origin}/${blok.link.cached_url}`} color="white">{blok.text}</Link></Button>
                    ))
                    : null}
                </ButtonGroup>
              </HStack>
            ) : (
              <>
                <ToggleButton
                  onClick={mobileNavbar.onToggle}
                  isOpen={mobileNavbar.isOpen}
                  aria-label="Open Menu"
                />

                <Drawer placement="top" isOpen={mobileNavbar.isOpen} onClose={mobileNavbar.onClose}>
                  <DrawerContent>
                    <DrawerBody mt="16">
                      <Stack spacing="6" align="stretch">
                        {story.content.navlinks
                          ? story.content.navlinks.map((blok) => (
                            <Button as="a" key={blok._uid} href={`${window.location.origin}/${blok.link.cached_url}`} color="white">{blok.text}</Button>
                          )) : null}
                      </Stack>
                    </DrawerBody>
                  </DrawerContent>
                </Drawer>
              </>
            )}
          </HStack>
        </Container>
      </Box>
    </Box>
  )
}

export default Navbar
