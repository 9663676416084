import { storyblokEditable } from "@storyblok/react";
import { Heading } from "@chakra-ui/react";

const Teaser = ({ blok }) => {
    return (
        <Heading {...storyblokEditable(blok)}>
            {blok.headline}
        </Heading>
    );
};

export default Teaser;