import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Text, HStack, Icon, VStack } from '@chakra-ui/react';
import { FaPlane, FaClock, FaPaperPlane, FaCity, FaCertificate } from 'react-icons/fa';
import { CountUp } from 'countup.js';

const STORYBLOK_API = process.env.REACT_APP_STORYBLOK_API_URL;
let token = process.env.REACT_APP_MODE === "production" ? process.env.REACT_APP_STORYBLOK_PUBLIC_TOKEN : process.env.REACT_APP_STORYBLOK_PREVIEW_TOKEN;

const SidebarWidget = () => {
    const [stats, setStats] = useState({
        flights: [],
        aircraft: [],
        airports: [],
        totalHours: 0,
    });

    useEffect(() => {
        const fetchData = async (folder) => {
            try {
                const response = await axios.get(STORYBLOK_API, {
                    params: {
                        "starts_with": `${folder}/`,
                        "token": token,
                        "filter_query": {
                            "component": {
                                "in": "flight,airport,aircraft"
                            }
                        }
                    }
                });
                return response.data.stories;
            } catch (error) {
                console.error("Error fetching data from Storyblok:", error);
                return [];
            }
        };

        const calculateTotalHours = (flights) => {
            return flights.reduce((acc, curr) => {
                const hours = parseFloat(curr.content.flight_hours);
                if (!isNaN(hours)) {
                    // Adjust the calculation here
                    const roundedHours = parseFloat(hours.toFixed(1));
                    return Math.round((acc + roundedHours) * 10) / 10;
                }
                return acc
            }, 0);
        };

        const loadData = async () => {
            const flights = await fetchData('flights');
            const aircraft = await fetchData('aircraft');
            const airports = await fetchData('airports');
            const totalHours = calculateTotalHours(flights);

            setStats({
                flights,
                aircraft,
                airports,
                totalHours,
            });
        };

        loadData();
    }, []);

    const countUpOptions = {
        decimalPlaces: 1,
    };
    new CountUp('hours', stats.totalHours, countUpOptions).start();
    new CountUp('flights', stats.flights).start();
    new CountUp('aircraft', stats.aircraft).start();
    new CountUp('airports', stats.airports).start();

    return (

        <Box
            p={6}
            color="gray.800"
            bg="blue.50" // Darker tone for contrast
            boxShadow="sm"
            rounded="xl"
            textAlign="center" // Center align might be overridden by HStack alignment
        >
            <Text mb={4} fontSize="4xl" fontWeight="bold" textAlign="left" borderBottomWidth={1}>My Flight Stats</Text>
            <VStack spacing={4} align="stretch">
                <HStack justifyContent="space-between">
                    <Icon as={FaCertificate} boxSize={5} color="gray.500" />
                    <Text fontSize="lg" fontWeight="bold" flex="1" textAlign="left">Certificate:</Text>
                    <Text fontSize="lg" flex="1" textAlign="right">Student Pilot</Text>
                </HStack>
                <HStack justifyContent="space-between">
                    <Icon as={FaPaperPlane} boxSize={5} color="gray.500" />
                    <Text fontSize="lg" fontWeight="bold" flex="4" textAlign="left">Total Flights:</Text>
                    <Text fontSize="lg" flex="1" textAlign="right">{stats.flights.length}</Text>
                </HStack>
                <HStack justifyContent="space-between">
                    <Icon as={FaClock} boxSize={5} color="gray.500" />
                    <Text fontSize="lg" fontWeight="bold" flex="4" textAlign="left">Total Hours:</Text>
                    <Text fontSize="lg" flex="1" textAlign="right" id="hours"></Text>
                </HStack>
                <HStack justifyContent="space-between">
                    <Icon as={FaPlane} boxSize={5} color="gray.500" />
                    <Text fontSize="lg" fontWeight="bold" flex="4" textAlign="left">Aircraft Flown:</Text>
                    <Text fontSize="lg" flex="1" textAlign="right">{stats.aircraft.length}</Text>
                </HStack>
                <HStack justifyContent="space-between">
                    <Icon as={FaCity} boxSize={5} color="gray.500" />
                    <Text fontSize="lg" fontWeight="bold" flex="4" textAlign="left">Airports Visited:</Text>
                    <Text fontSize="lg" flex="1" textAlign="right">{stats.airports.length}</Text>
                </HStack>
            </VStack>
        </Box>



    );
};

export default SidebarWidget;
