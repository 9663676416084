
import React, { useEffect } from 'react'
import { Box, Container, Stack } from '@chakra-ui/react'
import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import Navbar from "./navbar/Navbar";
import Footer from './Footer';


const Main = ({ blok }) => {

  useEffect(() => {
    document.title = "The Long Journey";
  }, [blok]);

  return (
    <Box>

      <Box>
        <Navbar editable={false} />
      </Box>
      <Container py="8" flex="1">
        <Stack
          direction={{
            base: 'column',
            lg: 'row',
          }}
          spacing={{
            base: '12',
            lg: '16',
          }}
          flex="1"
        >
          <Box as="main" role="main" width="full" {...storyblokEditable(blok)}>
            {blok.body
              ? blok.body.map((blok) => (
                <StoryblokComponent blok={blok} key={blok._uid} />
              ))
              : null}
          </Box>
          <Box as="aside"
            role="complementary"
            width={{
              base: 'full',
              lg: 'xl',
            }}
            alignSelf="start"
            position={{
              base: 'unset',
              lg: 'sticky',
            }} {...storyblokEditable(blok)}
          >
            {blok.sidebar
              ? blok.sidebar.map((blok) => (
                <StoryblokComponent blok={blok} key={blok._uid} />
              ))
              : null}

          </Box>
        </Stack>
      </Container>
      <Footer />
    </Box>

  )
}

export default Main
