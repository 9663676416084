import React from 'react';
import { AspectRatio } from '@chakra-ui/react';

const YoutubeEmbed = ({ blok }) => {
    const embedUrl = `https://www.youtube.com/embed/${blok.videoId}`;
    const ratio = blok.ratioWidth / blok.ratioHeight;

    return (
        <AspectRatio ratio={ratio} maxW="100%">
            <iframe
                width="100%"
                src={embedUrl}
                title={blok.videoTitle}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
        </AspectRatio>
    );
};

export default YoutubeEmbed;