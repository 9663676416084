import { StoryblokComponent, storyblokEditable } from "@storyblok/react";
import { useEffect } from "react";

import { Box, Container } from '@chakra-ui/react'
import Navbar from "./navbar/Navbar";
import Footer from "./Footer";



export default function Block({ blok }) {
    useEffect(() => {
        document.title = blok.title + " | The Long Journey";
    }, [blok.title]);
    console.log(blok)
    return (
        <Box>
            <Box>
                <Navbar editable={false} />
            </Box>
            <Container>
                <Box {...storyblokEditable(blok)}>
                    {blok.body
                        ? blok.body.map((blok) => (
                            <StoryblokComponent blok={blok} key={blok._uid} />
                        ))
                        : null}
                </Box>
            </Container>
            <Footer />
        </Box>
    )
};