import { Box, Text, Container, SimpleGrid } from '@chakra-ui/react'

const Footer = (props) => {
  return (
    <Box as="footer" role="contentinfo" bg="bg.accent.default" {...props}>
      <Container>
        <SimpleGrid columns={[1, 3]}>
          <Box>
            <Text color="white" py={8} fontSize="xs">
              <b>NOTE:</b> This site contains accounts and details of flight instruction; I am not a flight instructor, and any content on this site may have critical information omitted and is for entertainment purposes only. Please consult a Certified Flight Instructor for flight instruction, and always follow the guidance of the FAA.
            </Text>
          </Box>
          <Box />
          <Box>
            <Text color="white" py={8} align={["left", "right"]}>
              © {new Date().getFullYear()} Jason Long
            </Text>
          </Box>


        </SimpleGrid>
      </Container>
    </Box>
  )
}

export default Footer