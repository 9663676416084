import axios from 'axios';
import { useEffect, useState } from 'react';
import { Box, Link, List, ListItem, ListIcon } from '@chakra-ui/react';
import { FaPaperPlane } from 'react-icons/fa';

const STORYBLOK_API_URL = process.env.REACT_APP_STORYBLOK_API_URL;
let token = process.env.REACT_APP_MODE === "production" ? process.env.REACT_APP_STORYBLOK_PUBLIC_TOKEN : process.env.REACT_APP_STORYBLOK_PREVIEW_TOKEN;

// Determine the version based on the environment
let version = process.env.REACT_APP_MODE === "production" ? "published" : "draft";

const fetchPosts = async (slug) => {
    const response = await axios.get(STORYBLOK_API_URL, {
        params: {
            token: token,
            version: version, // Add the version parameter
            starts_with: slug,
            is_startpage: 0,
            sort_by: '',
        },
    });

    return response.data.stories;
}

const FullIndex = ({ blok }) => {
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        fetchPosts(blok.slug).then((posts) => {
            setPosts(posts);
        });
    }, [blok.slug]);
    return (
        <Box my={4}>
            <List spacing={2}>
                {posts.map((post) => (
                    <ListItem key={post.id}>
                        <ListIcon as={FaPaperPlane} color="blue.500" />
                        <Link href={`/flights/${post.slug}`}>{post.name}</Link>
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};


export default FullIndex;